var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    moc: _vm.moc,
                    disabled: _vm.disabled,
                    attachInfo: _vm.attachInfo,
                  },
                  on: {
                    "update:moc": function ($event) {
                      _vm.moc = $event
                    },
                    "update:disabled": function ($event) {
                      _vm.disabled = $event
                    },
                    "update:attachInfo": function ($event) {
                      _vm.attachInfo = $event
                    },
                    "update:attach-info": function ($event) {
                      _vm.attachInfo = $event
                    },
                    saveCallback: _vm.saveCallback,
                    approvalCallback: _vm.approvalCallback,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }